<template>
  <div>
    <div class="item-chip">
      <div class="item-chip-icon">
        <slot name="icon"></slot>
      </div>
      <div class="item-chip-text">
        <slot name="text"></slot>
      </div>
      <div v-if="removable">
        <FilledClose class="close-button" @click="$emit('remove')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FilledClose: () => import('@/assets/icons/filled-close.svg')
  },
  props: {
    removable: Boolean
  },
  name: 'ItemChip'
}
</script>

<style lang="scss" scoped>
.item-chip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  border-radius: 30px;
  background-color: var(--neutral-200);
  margin: 0 8px 0 0;
  padding: 2px 4px;

  .item-chip-text {
    font-weight: 600;
    font-size: 13px;
    color: var(--type-active);
    margin-right: 2px;
  }

  .item-chip-icon {
    margin-right: 8px;
    font-size: 10px;
  }

  .close-button {
    margin: 0 2px 4px 2px;
  }
}
</style>
